import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`MaxMind minFraud Score, minFraud Insights, and minFraud Factors provide a modern
RESTful way to access the minFraud data services. This document outlines the
major changes for developers when using these services.`}</p>
    <p>{`For more information about ongoing enhancements to the minFraud services, see
our `}
      <a {...{
        "href": "/minfraud/release-notes",
        "parentName": "p"
      }}>{`release notes`}</a>
      {`.`}</p>
    <div {...{
      "id": "toc-general-changes"
    }}><h2 {...{
        "id": "general-changes",
        "parentName": "div"
      }}>{`General Changes`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`The minFraud web services now follow REST principles.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`Authentication is done using
`}
        <a {...{
          "href": "https://en.wikipedia.org/wiki/Basic_access_authentication",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`basic authentication`}</a>
        {`
over an TLS connection rather than passing the license key as a query parameter.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`All requests are made by a POST of a structured JSON document to the web
service.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`When an error occurs, we now return an appropriate HTTP error code instead of
simply returning a 200 with an error in the document. We also return a JSON
document with details about the error in most cases.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`Successful responses also return a JSON document. All documents, both successful
and error, are in the form of a JSON object.`}</p></div>
    <div {...{
      "id": "toc-data-changes"
    }}><h2 {...{
        "id": "data-changes",
        "parentName": "div"
      }}>{`Data Changes`}</h2>
      <div {...{
        "id": "toc-inputs",
        "parentName": "div"
      }}><h3 {...{
          "id": "inputs",
          "parentName": "div"
        }}>{`Inputs`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`The following inputs have been added to help us provide you with the most
accurate risk assessment possible. Please see the
`}
          <a {...{
            "href": "/minfraud/api-documentation/requests",
            "parentName": "p"
          }}>{`Request API documentation`}</a>
          {` for a complete
description.`}</p>
        <ul {...{
          "parentName": "div"
        }}>

          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`credit_card`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`token`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`device`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`session_age`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`session_id`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`event`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`time`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`type`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`billing`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`first_name`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`last_name`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`company`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`address`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`address_2`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`phone_number`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`phone_country_code`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`shipping`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`first_name`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`last_name`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`company`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`address_2`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`phone_number`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`phone_country_code`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`delivery_speed`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`account`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`user_id`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`email`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`first_seen`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`payment`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`processor`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`was_authorized`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`decline_code`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`credit_card`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`last_digits`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`order`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`affiliate_id`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`discount_code`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`subaffiliate_id`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`referrer_uri`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`shopping_cart`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`category`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`item_id`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`quantity`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`price`}</inlineCode></li>

            </ul>

          </li>

        </ul></div>
      <div {...{
        "id": "toc-minfraud-score-outputs",
        "parentName": "div"
      }}><h3 {...{
          "id": "minfraud-score-outputs",
          "parentName": "div"
        }}>{`minFraud Score Outputs`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`minFraud Score does not provide any new data outputs. Rather it provides a
`}
          <a {...{
            "href": "/minfraud/api-documentation/responses",
            "parentName": "p"
          }}>{`simple JSON document`}</a>
          {` containing the
transaction’s `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`risk_score`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`id`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`credits_remaining`}</inlineCode>
          {`, and any warnings related
to the input provided.`}</p></div>
      <div {...{
        "id": "toc-minfraud-insights-outputs",
        "parentName": "div"
      }}><h3 {...{
          "id": "minfraud-insights-outputs",
          "parentName": "div"
        }}>{`minFraud Insights Outputs`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`minFraud Insights response data builds on our previous web service outputs,
improving organization and consistency while adding several new data points.`}</p>
        <p {...{
          "parentName": "div"
        }}>{`Beyond the new outputs listed below, the service provides
`}
          <a {...{
            "href": "/geoip/docs/web-services/responses/#geoip2-insights-body-example",
            "parentName": "p"
          }}>{`GeoIP2 Insights data`}</a>
          {`
for the IP address in the `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`ip_address`}</inlineCode>
          {` object.`}</p>
        <p {...{
          "parentName": "div"
        }}><inlineCode {...{
            "parentName": "p"
          }}>{`proxyScore`}</inlineCode>
          {` has been replaced with the IP address risk, `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`/ip_address/risk`}</inlineCode>
          {`.
Whereas proxyScore ranged from 0-4, the IP address ranges from 0.01 to 99,
similar to `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`risk_score`}</inlineCode>
          {`.`}</p>
        <p {...{
          "parentName": "div"
        }}><inlineCode {...{
            "parentName": "p"
          }}>{`countryMatch`}</inlineCode>
          {` has been replaced with `}
          <inlineCode {...{
            "parentName": "p"
          }}>{`/billing_address/is_in_ip_country`}</inlineCode>
          {` and
`}
          <inlineCode {...{
            "parentName": "p"
          }}>{`/shipping_address/is_in_ip_country`}</inlineCode>
          {`.`}</p></div>
      <div {...{
        "id": "toc-new-outputs",
        "parentName": "div"
      }}><h3 {...{
          "id": "new-outputs",
          "parentName": "div"
        }}>{`New Outputs`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`The following outputs have been added.`}</p>
        <ul {...{
          "parentName": "div"
        }}>

          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`credit_card`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`brand`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`is_prepaid`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`is_virtual`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`type`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`device`}</inlineCode>
              {` (requires `}
              <a {...{
                "href": "/minfraud/track-devices",
                "parentName": "p"
              }}>{`Device Tracking Add-on`}</a>
              {`)`}</p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`id`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`confidence`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`last_seen`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`local_time`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`disposition`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`action`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`reason`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`email`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`first_seen`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`ip_address`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}>

                <p {...{
                  "parentName": "li"
                }}><inlineCode {...{
                    "parentName": "p"
                  }}>{`risk`}</inlineCode></p>

              </li>


              <li {...{
                "parentName": "ul"
              }}>

                <p {...{
                  "parentName": "li"
                }}><inlineCode {...{
                    "parentName": "p"
                  }}>{`location`}</inlineCode></p>


                <ul {...{
                  "parentName": "li"
                }}>

                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`average_income`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`local_time`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`population_density`}</inlineCode></li>

                </ul>

              </li>


              <li {...{
                "parentName": "ul"
              }}>

                <p {...{
                  "parentName": "li"
                }}><inlineCode {...{
                    "parentName": "p"
                  }}>{`registered_country`}</inlineCode></p>

              </li>


              <li {...{
                "parentName": "ul"
              }}>

                <p {...{
                  "parentName": "li"
                }}><inlineCode {...{
                    "parentName": "p"
                  }}>{`represented_country`}</inlineCode></p>

              </li>


              <li {...{
                "parentName": "ul"
              }}>

                <p {...{
                  "parentName": "li"
                }}><inlineCode {...{
                    "parentName": "p"
                  }}>{`traits`}</inlineCode></p>


                <ul {...{
                  "parentName": "li"
                }}>

                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`autonomous_system_number`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`autonomous_system_organization`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`is_anonymous`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`is_anonymous_vpn`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`is_hosting_provider`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`is_public_proxy`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`is_satellite_provider`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`is_tor_exit_node`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`is_residential_proxy`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`network`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`static_ip_score`}</inlineCode></li>


                  <li {...{
                    "parentName": "ul"
                  }}><inlineCode {...{
                      "parentName": "li"
                    }}>{`user_count`}</inlineCode></li>

                </ul>

              </li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`shipping_address`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`latitude`}</inlineCode>
                <sup>{`*`}</sup></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`longitude`}</inlineCode>
                <sup>{`*`}</sup></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`distance_to_ip_location`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`distance_to_billing_address`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`is_in_ip_country`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`shipping_phone`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`country`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`network_operator`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`number_type`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`is_voip`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`billing_address`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`latitude`}</inlineCode>
                <sup>{`*`}</sup></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`longitude`}</inlineCode>
                <sup>{`*`}</sup></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`distance_to_ip_location`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`is_in_ip_country`}</inlineCode></li>

            </ul>

          </li>


          <li {...{
            "parentName": "ul"
          }}>

            <p {...{
              "parentName": "li"
            }}><inlineCode {...{
                "parentName": "p"
              }}>{`billing_phone`}</inlineCode></p>


            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`country`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`network_operator`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`number_type`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`is_voip`}</inlineCode></li>

            </ul>

          </li>

        </ul>
        <small>
          <p>{`*`}
            {` Latitude and longitude are not precise and should not be used to identify a
particular street address or household.`}</p>
        </small></div>
      <div {...{
        "id": "toc-deprecated-outputs",
        "parentName": "div"
      }}><h3 {...{
          "id": "deprecated-outputs",
          "parentName": "div"
        }}>{`Deprecated Outputs`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`The following outputs have been removed without a direct replacement.`}</p>
        <ul {...{
          "parentName": "div"
        }}>

          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`ip_corporateProxy`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`highRiskCountry`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`highRiskUsername`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`highRiskPassword`}</inlineCode></li>


          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`custPhoneInBillingLoc`}</inlineCode></li>

        </ul></div>
      <div {...{
        "id": "toc-minfraud-factors-outputs",
        "parentName": "div"
      }}><h3 {...{
          "id": "minfraud-factors-outputs",
          "parentName": "div"
        }}>{`minFraud Factors Outputs`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`In addition to the changes in minFraud Insights, minFraud Factors also includes
the following outputs:`}</p>
        <ul {...{
          "parentName": "div"
        }}>

          <li {...{
            "parentName": "ul"
          }}><inlineCode {...{
              "parentName": "li"
            }}>{`subscores`}</inlineCode>
            <ul {...{
              "parentName": "li"
            }}>

              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`avs_result`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`billing_address`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`billing_address_distance_to_ip_location`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`browser`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`chargeback`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`country`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`country_mismatch`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`cvv_result`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`device`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`email_address`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`email_domain`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`email_local_part`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`email_tenure`}</inlineCode>
                {`(deprecated)`}</li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`ip_tenure`}</inlineCode>
                {` (deprecated)`}</li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`issuer_id_number`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`order_amount`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`phone_number`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`shipping_address`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`shipping_address_distance_to_ip_location`}</inlineCode></li>


              <li {...{
                "parentName": "ul"
              }}><inlineCode {...{
                  "parentName": "li"
                }}>{`time_of_day`}</inlineCode></li>

            </ul></li>

        </ul></div></div>
    <div {...{
      "id": "toc-more-information"
    }}><h2 {...{
        "id": "more-information",
        "parentName": "div"
      }}>{`More Information`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`For more information on these web services, see the full
`}
        <a {...{
          "href": "/minfraud/api-documentation",
          "parentName": "p"
        }}>{`API documentation`}</a>
        {`.`}</p></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      